import { type InviteFragmentFragment, type User } from '@apps/www/src/__generated__/graphql';
import SVAvatarStackPlusIcon from '@pkgs/shared-client/img/avatar-stack-plus-inlined.svg';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVAvatar from './SVAvatar';
import SVDropdown from './SVDropdown';
import SVDropdownContent from './SVDropdownContent';

const SIZES = {
	DEFAULT: 'default',
	MEDIUM: 'medium',
	LARGE: 'large',
	EXTRA_LARGE: 'extra_large',
} as const;

type BaseItemProps = React.PropsWithChildren<{
	className?: string;
	title?: string;
	size: ValueOf<typeof SIZES>;
	isTight: boolean;
}>;
const _BaseItem = ({ className, title, size, isTight, children }: BaseItemProps) => (
	<span
		className={twMerge(
			'border-background bg-background relative overflow-hidden rounded-full first:ml-0',
			size === SIZES.DEFAULT && 'h-6 w-6 min-w-[20px] border-2',
			size === SIZES.DEFAULT && (isTight ? '-ml-[10px]' : '-ml-2'),
			size === SIZES.EXTRA_LARGE && 'h-12 w-12 min-w-[48px] border-2',
			size === SIZES.EXTRA_LARGE && (isTight ? '-ml-[16px]' : '-ml-4'),
			size === SIZES.LARGE && 'h-8 w-8 border-2',
			size === SIZES.LARGE && (isTight ? '-ml-[12px]' : '-ml-3'),
			size === SIZES.MEDIUM && 'h-6 w-6',
			className,
		)}
		title={title}
	>
		{children}
	</span>
);

const _Count = ({
	className,
	size,
	isAdd,
	isTight,
	children,
}: BaseItemProps & { isAdd?: boolean }) => (
	<_BaseItem
		className={twMerge(
			'flex-center type-small pointer-events-none whitespace-nowrap bg-gray-800 leading-none text-gray-400',
			size === SIZES.DEFAULT && 'text-[9px]',
			size === SIZES.LARGE && 'text-[14px]',
			!isAdd && (size === SIZES.LARGE ? 'px-3' : 'px-1'),
			!isAdd && 'w-auto',
			className,
		)}
		size={size}
		isTight={isTight}
	>
		{children}
	</_BaseItem>
);

const _Add = ({ size, isTight, children }: Omit<BaseItemProps, 'className'>) => (
	<_Count
		className={clsx(
			size === SIZES.DEFAULT && 'text-[14px]',
			size === SIZES.LARGE && 'text-[20px]',
		)}
		size={size}
		isAdd={true}
		isTight={isTight}
	>
		{children}
	</_Count>
);

const _Item = ({
	name,
	avatarURL,
	isInvite,
	isTight,
	size,
}: Omit<BaseItemProps, 'className'> & {
	name: User['name'] | null | undefined;
	avatarURL: User['avatarURL'] | null;
	isInvite: boolean;
}) => (
	<_BaseItem isTight={isTight} size={size} title={name || undefined}>
		<SVAvatar src={avatarURL} isFaded={isInvite} className={clsx('h-full w-full')} />
	</_BaseItem>
);

type UserFragment = {
	_id: User['_id'];
	name: User['name'];
	avatarURL: User['avatarURL'];
	username: User['username'];
};

// If we need to show the dropdown, we need some more information from the User object
type UserFragmentForDropdown = UserFragment & {
	url: User['url'];
	isPro: User['isPro'];
	canFollow: User['canFollow'];
	isFollowing: User['isFollowing'];
	isFollowingBack: User['isFollowingBack'];
};

type LikeUser = UserFragment | { user: UserFragment };
type LikeUserForDropdown = UserFragmentForDropdown | { user: UserFragmentForDropdown };

type LikeUserOrInvite =
	| UserFragment
	| { user: UserFragment }
	| (InviteFragmentFragment & { isInvite: boolean });

type AvatarsProps = {
	users: LikeUserOrInvite[];
	usersCount: number;
	size: BaseItemProps['size'];
	showAdd: boolean;
	isTight: BaseItemProps['isTight'];
	onClick?: Props['onClick'];
	spaced?: boolean;
};

const _Avatars = React.forwardRef<HTMLDivElement, AvatarsProps>(
	({ users, usersCount, size, showAdd, isTight, onClick, spaced }, forwardedRef) => (
		<div
			ref={forwardedRef}
			className={clsx(
				'relative -mx-[2px] flex items-center transition-all',
				onClick && 'cursor-pointer hover:brightness-75',
				!isTight && 'min-h-[44px]',
				spaced && 'block space-x-2',
			)}
			onClick={onClick}
			data-testid="avatars-stack"
		>
			<>
				{users.map((item) => {
					const user: UserFragment | null =
						'user' in item && item.user ? item.user : (item as UserFragment);

					return (
						<_Item
							key={user?.username || ('email' in item ? item.email : '')}
							name={user?.name || ('email' in item ? item.email : '')}
							avatarURL={user?.avatarURL}
							size={size}
							isTight={isTight}
							isInvite={'isInvite' in item ? item.isInvite : false}
						/>
					);
				})}
			</>
			{usersCount > users.length && (
				<_Count key=".count" size={size} isTight={isTight} className="h6 w-6">
					<span>+{Math.min(999, usersCount - users.length)}</span>
				</_Count>
			)}
			{showAdd && (
				<_Add key=".add" size={size} isTight={isTight}>
					<SVAvatarStackPlusIcon className="text-primary h-3" />
				</_Add>
			)}
		</div>
	),
);

const defaultProps: {
	visibleCount: number;
	size: ValueOf<typeof SIZES>;
	showAdd: boolean;
	showDropdown: boolean;
} = {
	visibleCount: 6,
	size: SIZES.DEFAULT,
	showAdd: false,
	showDropdown: true,
};

export type Props = Partial<typeof defaultProps> & {
	invites?: InviteFragmentFragment[];
	firstIsAuthor?: boolean;
	onClick?: (event: React.UIEvent) => void;
	title?: string;
	spaced?: boolean;
} & (
		| {
				users: LikeUserForDropdown[];
		  }
		| {
				users: LikeUser[];
				showDropdown: false;
		  }
	);

const SVAvatarsStack = ({
	visibleCount = defaultProps.visibleCount,
	users,
	invites = [],
	size = defaultProps.size,
	showAdd = defaultProps.showAdd,
	showDropdown,
	firstIsAuthor = false,
	spaced,
	onClick,
}: Props) => {
	const allUsers: LikeUserOrInvite[] = [...users];

	if (!showDropdown && invites) {
		invites.forEach((invite) => {
			allUsers.push({
				...invite,
				isInvite: true,
			});
		});
	}

	const visibleUsers = allUsers.slice(0, visibleCount - (allUsers.length > visibleCount ? 1 : 0));
	const isTight = visibleCount <= 3;

	if (showDropdown && allUsers.length > 0 && !showAdd) {
		return (
			<SVDropdown
				renderTrigger={({ isOpen: _, ...props }) => (
					<_Avatars
						users={visibleUsers}
						usersCount={allUsers.length}
						size={size}
						showAdd={showAdd}
						isTight={isTight}
						onClick={onClick}
						spaced={spaced}
						{...props}
					/>
				)}
				renderContent={() => (
					<SVDropdownContent.Users users={users} firstIsAuthor={firstIsAuthor} />
				)}
			/>
		);
	}

	return (
		<_Avatars
			users={visibleUsers}
			usersCount={allUsers.length}
			size={size}
			showAdd={showAdd}
			spaced={spaced}
			isTight={isTight}
			onClick={onClick}
		/>
	);
};

SVAvatarsStack.defaultProps = defaultProps;

SVAvatarsStack.SIZES = SIZES;

export default SVAvatarsStack;
